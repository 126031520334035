'use client'

import { type IOption, useProductCustomizedContext } from "@context/product-customized"

import Content from "@/components/atoms/content"
import Container from "react-bootstrap/Container"

import renderConfigurationOption from "./customize/render-configuration-option"
import CustomizeInfo from "./customize/info"

const CustomizedProducts = () => {

    const { template, values, setValues, stepOneOptions } = useProductCustomizedContext()

    if (undefined === template || null === template) {
        return null
    }

    const { displayName, description } = template

    return (
        <Container className={'mb-4  p-4  bg-gray-100  border  border-secondary'}>
            {displayName && <span className={'H5-Headline-Default  d-block  mb-3'}>{displayName}</span>}
            {description && <Content html={description} />}
            {stepOneOptions?.map((option: IOption, index: number) =>
                renderConfigurationOption(option, values[option.id]?.value, setValues, { isLast: index < stepOneOptions.length - 1 }))
            }
            <CustomizeInfo />
        </Container>
    )
}

export default CustomizedProducts
