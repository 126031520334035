'use client'

import Button from "@components/atoms/button"

import { useProductTableContext } from "@context/product-table"
import classNames from "classnames"

const AddToCartLink = ({ productId, seoPathInfo }: { productId: string, seoPathInfo: string }) => {
    const { products } = useProductTableContext()

    const product = products[productId] ?? null

    return (
        <Button
            href={'/' + seoPathInfo + (product?.quantity ? '?qty=' + product.quantity * product.purchaseUnit : '')}
            className={classNames('p-2  border-0  btn-secondary', { 'disabled': product?.minPurchase > product?.quantity || (product?.maxPurchase && product?.maxPurchase < product?.quantity) })}

            icon={'cart'}
            iconVariant={'white'}

            disabled={product?.minPurchase > product?.quantity || (product?.maxPurchase && product?.maxPurchase < product?.quantity)}
            aria-label={'In den Warenkorb'}
            as={'a'}
        />
    )
}

export default AddToCartLink
