'use client'

import { type IOption } from "@context/product-customized"

import FormControl from "@components/atoms/form-control"
import FormLabel from "@components/atoms/form-label"
import t from "@/utils/translation-faker"

const ImageUpload = ({ id, required, value, typeProperties, setValues }: { id: string, value: string, required: boolean, typeProperties: { [key: string]: string }, setValues?: (values: any) => void, }) => {

    return (
        <>
            <div className={'d-inline-flex gap-2'}>
                <FormControl
                    key={`${id}_control`}
                    name={id}
                    type={'file'}
                    required={required}
                    className={'mb-1'}
                    value={value}
                    isInvalid={'' === value}
                    onChange={(e) => setValues?.((values: any) => {
                        return Object.assign({}, values, { [`${id}`]: { value: e.target.value } })
                    })}
                    accept={['.pdf', '.jpg', '.png', '.svg', '.eps', '.tif', '.gif', '.webp', '.bmp'].filter(key => !typeProperties.excludedExtensions.includes(key.substring(1))).join(',')}
                />
                <span className={'d-inline-flex  justify-content-center  align-items-center m-0 icon  icon--trash cursor--pointer'} onClick={() => setValues?.((values: any) => {
                    return Object.assign({}, values, { [`${id}`]: { value: '' } })
                })} />
            </div>
            {('' === value) && (
                <span className={'d-block fs-9 pb-2 text-danger'}>Bitte wählen Sie eine Datei aus.</span>
            )}
            <span className={'fs-9  fw-semibold'}>{t('allowed_extensions')}: <span className={'fw-normal'}>{['.pdf', '.jpg', '.png', '.svg', '.eps', '.tif', '.gif', '.webp', '.bmp'].filter(key => !typeProperties.excludedExtensions.includes(key.substring(1))).join(', ')}</span></span>
            <span className={'fs-9  fw-semibold'}>{t('allowed_file_size')}: <span className={'fw-normal'}>{typeProperties.maxFileSize}MB</span></span>
        </>
    )
}

const renderConfigurationOption = ({
    id,
    displayName,
    placeholder,
    description: _,
    values,
    required,
    type,
    typeProperties
}: IOption,
    value: string,
    callback?: (values: any) => void,
    options?: {
        isLast?: boolean
        label?: {
            className?: string
        }
    }
) => {
    const fieldRenderer = () => {
        switch (type) {
            case 'select':
                return (
                    <FormControl
                        key={`${id}_control`}
                        name={id}
                        as={'select'}
                        bsPrefix={'form-select'}
                        required={required}
                        onChange={(e) => callback?.((values: any) => {
                            let _values = values
                            if ('-' === e.target.value) {
                                delete _values[id]
                            } else {
                                _values = Object.assign({}, _values, { [`${id}`]: { value: e.target.value } })
                            }
                            return _values
                        })}
                        value={value}
                    >
                        <option key={'-'} value={'-'}>Bitte auswählen</option>
                        {values.map(({ id, displayName }) => (
                            <option key={id} value={id} >{displayName}</option>
                        ))}
                    </FormControl>
                )
            case 'textarea':
                return (
                    <FormControl
                        key={`${id}_control`}
                        name={id}
                        as={'textarea'}
                        rows={7}
                        required={required}
                        onChange={(e) => callback?.((values: any) => {
                            return Object.assign({}, values, { [`${id}`]: { value: e.target.value } })
                        })}
                        value={value}
                        placeholder={placeholder ?? t('Haben Sie Fragen?')}
                    />
                )
            case 'imageupload':
                return <ImageUpload id={id} required={required} typeProperties={typeProperties} setValues={callback} value={value} />

            default:
                return null
        }
    }

    return (
        <div key={id} className={'d-flex flex-column mb-3'}>
            <FormLabel key={`${id}_label`} className={options?.label?.className ?? 'fw-semibold'}>{displayName}</FormLabel>
            {fieldRenderer()}
        </div>
    )
}

export default renderConfigurationOption
