'use client'

import type { CustomFormProps } from '.';

import { useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Form from '@components/atoms/form/Form';
import { object, string, boolean } from 'yup';
import Button from '@components/atoms/button';
import FormGroup from '@components/molecules/form/form-group';
import FormLabel from '@components/atoms/form-label';
import FormCheck from '@components/atoms/form-check';
import FormControl from '@components/atoms/form-control';

import dynamic from 'next/dynamic';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { toast } from 'react-toastify';

//import linkedInSendEvent from '@services/linkedin-send-event';
import { useParams, useSearchParams } from 'next/navigation';
import { useLocale } from 'next-intl';

const Option = dynamic(() => import('@components/atoms/option'));
const ModalCMS = dynamic(() => import('@components/molecules/modal/ModalCMS'));

const CustomForm = ({ id, title, successMessage, groups = [], labels }: CustomFormProps) => {

    const t = (str: string) => labels?.[str] ?? str

    const captchaRef = useRef<HCaptcha>(null);

    const searchParams = useSearchParams()
    const params = useParams()
    const locale = useLocale()

    const FORM_CONTROL_LABEL_CLASSNAMES = 'fw-bold'
    const FORM_CONTROL_CLASSNAMES = 'fs-8 py-2 mb-2 form-control'

    const [loading, setLoading] = useState<boolean>(false)
    const [sent, setSent] = useState<boolean>(false)
    const [token, setToken] = useState<string | null>(null);

    let validationSchema = {}
    groups.forEach(({ fields }) => {
        fields.forEach(({ technicalName, type, required, errorMessage }) => {
            if (false === required) return
            if ('checkbox' === type) {
                validationSchema = Object.assign(validationSchema, { [technicalName]: boolean().oneOf([true], errorMessage ?? t('validation_required')) })
            } else {
                validationSchema = Object.assign(validationSchema, { [technicalName]: string().required(errorMessage ?? t('validation_required')) })
            }
        })
    })

    let initialValues = {}
    groups.forEach(({ fields }) => {
        fields.forEach(({ technicalName, type }) => {
            switch (type) {
                case 'checkbox':
                    initialValues = Object.assign(initialValues, { [technicalName]: 'on' === (searchParams.get(technicalName) ?? params[technicalName]) })
                    return
                default:
                    initialValues = Object.assign(initialValues, { [technicalName]: searchParams.get(technicalName) ?? params[technicalName] ?? '' })
                    return
            }
        })
    })

    const handleSubmit = async (values: any) => {
        captchaRef.current?.execute();

        try {
            const res = await fetch('/api/swag/cms-extensions/form?locale=' + locale, {
                method: "POST",
                body: JSON.stringify({ ...values, formId: id, captcha: token })
            });

            if (200 === res.status) {
                //facebookSendEvent('Contact', {})
                //linkedInSendEvent({ conversionId: 11755490 })
                setSent(true)
            } else {
                const json = await res.json()
                if (json.errors) {
                    json.errors.forEach(({ detail, source }: any) => {
                        toast.error(detail + '\n' + source.pointer)
                    })
                }
            }
            setLoading(false)
        } catch (err) {
            console.log(err);
            setLoading(false)
        } finally {
            captchaRef.current?.resetCaptcha();
        }
    }

    const onExpire = () => {
        console.log("hCaptcha Token Expired");
    };

    const onError = (err: any) => {
        console.log(`hCaptcha Error: ${err}`);
    };

    return (
        <Container className={'px-0'} fluid>
            <Container className={'px-0  mb-4'}>
                {false === sent ? (
                    <Form key={'custom_form_' + id} onSubmit={handleSubmit} validationSchema={object().shape(validationSchema)} initialValues={initialValues}>
                        {title && <h2>{title}</h2>}
                        {groups.map(({ technicalName, fields }) => {
                            return (
                                <fieldset key={'custom_form_group_' + technicalName} className={'mb-4'}>
                                    {fields.map(({ id, type, technicalName, label, placeholder, config }) => {
                                        const renderField = () => {
                                            switch (type) {
                                                case 'select':
                                                    return (
                                                        <FormControl id={technicalName} key={'custom_form_group_control_' + id} as={type} className={FORM_CONTROL_CLASSNAMES}>
                                                            <Option key={'custom_form_group_control_' + id + '_select_none'} value={''}>{'keine Angabe'}</Option>
                                                            {config?.options?.map((option: any) => <Option key={'custom_form_group_control_' + id + '_select_' + option} value={option}>{option}</Option>)}
                                                        </FormControl>
                                                    )

                                                case 'checkbox':
                                                    let _label = label
                                                    if ('datenschutz' === technicalName) {
                                                        _label = (<ModalCMS title={label} buttonText={label} cmsPageId={process.env.NEXT_PUBLIC_PRIVACY_POLICY ?? ''} />)
                                                    }
                                                    return <FormCheck id={technicalName} key={'custom_form_group_control_' + id} type={type} label={_label} className={'d-inline-flex  align-items-center  gap-2  fs-8  py-2  mb-2'} />

                                                case 'textarea':
                                                    return <FormControl id={technicalName} key={'custom_form_group_control_' + id} as={type} className={`h-100 ${FORM_CONTROL_CLASSNAMES}`} style={{ minHeight: '150px' }} />

                                                default:
                                                    return <FormControl id={technicalName} key={'custom_form_group_control_' + id} type={type} placeholder={placeholder} className={FORM_CONTROL_CLASSNAMES} ></FormControl>
                                            }
                                        }

                                        return (
                                            <FormGroup key={'custom_form_group_wrapper_' + technicalName} className={'fs-8'}>
                                                {label && 'checkbox' !== type && <FormLabel htmlFor={technicalName} className={FORM_CONTROL_LABEL_CLASSNAMES}>{label}</FormLabel>}
                                                {renderField()}
                                            </FormGroup>
                                        )
                                    })}
                                </fieldset>
                            )
                        })}
                        {process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY &&
                            <HCaptcha
                                ref={captchaRef}
                                sitekey={process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
                                onVerify={setToken}
                                onError={onError}
                                onExpire={onExpire}
                            />
                        }
                        <Button type={'submit'} className={'text-white'} disabled={loading}>{t('submit')}</Button>
                    </Form>
                ) : (
                    <div className={'text-success'} dangerouslySetInnerHTML={{ __html: successMessage }}></div>
                )}
            </Container>
        </Container>
    )
}

export default CustomForm
