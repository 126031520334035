'use client'

import { Fragment, useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import Button from "@/components/atoms/button"

import { useProductPurchaseContext } from "@context/product-purchase"
import { type IOption, useProductCustomizedContext } from '@context/product-customized';

import renderConfigurationOption from './customize/render-configuration-option';
import { handleAddCustomizedToCart } from '@app/actions';
import t from '@/utils/translation-faker';
import CustomizeInfo from './customize/info';
import { useCartContext } from '@context/cart-provider';
import { toast } from 'react-toastify';

const ConfigureProduct = () => {
    const [show, setShow] = useState<boolean>(false);

    const { setCart, setLoading: cartSetLoading, setShowOffcanvas } = useCartContext()
    const { product, amount } = useProductPurchaseContext()
    const { template, stepOneOptions, stepTwoOptions, values, setValues, isNextAllowed, isAddToCartAllowed } = useProductCustomizedContext()

    let id
    let parentId = null
    let name = undefined

    if (product) {
        id = product.id
        parentId = product.parentId
        name = product.translated?.name ?? product.name
    }

    const handleClose = () => {
        let _values = values
        stepTwoOptions.forEach(({ id, type }: { id: string, type: string }) => {
            if (_values.hasOwnProperty(id) && 'imageupload' === type) {
                _values = Object.assign({}, values, { [`${id}`]: { value: '' } })
            }
        })
        setValues(_values)

        setShow(false)
    }
    const handleShow = () => setShow(true);

    const handleAddCustomizedToCartWithId = handleAddCustomizedToCart.bind(null, template.id, id)

    const onSubmit = async (formData: FormData) => {
        cartSetLoading(true)

        const updateCart = (cart: any) => {
            if (cart.errors.length) {
                cart.errors.forEach(({ detail }: { detail: string }) => {
                    toast.error(t(detail))
                })
            } else {
                setCart(cart)

                cartSetLoading(false)
                setShowOffcanvas(true)
                setShow(false)
            }
        }

        await handleAddCustomizedToCartWithId(formData).then(updateCart).catch(({ ...data }) => console.log('test', data))
    }

    return (
        <>
            {isNextAllowed() && (
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size={'lg'}
                >
                    <Modal.Header closeButton className={'px-4  bg-gray-200'}>
                        <Modal.Title>{'Druckdaten Upload'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={'px-4  pb-4  bg-gray-200'}>
                        {name && <p><span className={'fw-bold'}>Artikel:</span> {name}</p>}
                        <form action={onSubmit}>
                            <input key={'qty'} type={'hidden'} name={'qty'} value={amount} />
                            {0 < Object.keys(values).length && (
                                <div className={'mb-3'}>
                                    {stepOneOptions.map(({ id, displayName, values: optionValues }: IOption) => {
                                        if (values.hasOwnProperty(id)) {
                                            let valueId = values[id].value
                                            let value = optionValues.find(({ id }) => valueId === id)
                                            return (
                                                value ? (
                                                    <Fragment key={id}>
                                                        <input type={'hidden'} name={id} value={valueId} />
                                                        <span className={'d-block'}><span className={'fw-bold'}>{displayName}:</span> {value.displayName}</span>
                                                    </Fragment>
                                                ) : null
                                            )
                                        }
                                        return null
                                    })}
                                </div>
                            )}
                            {stepTwoOptions?.map((option: IOption) =>
                                renderConfigurationOption(option, values[option.id]?.value, setValues, { isLast: false, label: { className: 'fw-bold' } })
                            )}
                            <button type={'submit'} className={'w-100  btn  btn-secondary  py-2'} disabled={!isAddToCartAllowed()}>{t('add_to_cart')}</button>
                        </form>
                        <CustomizeInfo />
                    </Modal.Body>
                </Modal>
            )}
            <Button
                variant={'secondary'}
                disabled={!isNextAllowed()}
                className={'w-100  justify-content-center  py-4'}
                aria-label={`${name} konfigurieren.`}
                type={'submit'}
                style={{ width: 38, height: 38 }}
                onClick={handleShow}
            >
                {'Druckdaten hochladen'}
            </Button>
        </>
    )
}

export default ConfigureProduct
