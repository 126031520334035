'use client'

import { default as FormattedPrice } from '@components/atoms/price/Client';

import { useProductPurchaseContext } from "@context/product-purchase";

const PricePerUnit = ({ }: {}) => {

    const { product, calculatedPrice } = useProductPurchaseContext()

    if (undefined === product || undefined === calculatedPrice) {
        return null
    }

    const { taxId } = product

    return (
        <span className={'fw-bold  fs-9  text-gray-600'}>
            <FormattedPrice unitPrice={calculatedPrice.unitPrice} purchaseUnit={1} referenceUnit={1} taxId={taxId} />{' '}<span className={'fw-normal'}>{'pro VE'}</span>
        </span>
    )
}

export default PricePerUnit
