'use client'

import { useProductPurchaseContext } from "@context/product-purchase"
import { ReactNode } from "react"
import { default as FormattedPrice } from '@components/atoms/price/Client';
import Tax from "@components/atoms/tax";

const Price = ({ children, className }: { children: ReactNode, className?: string, }) => {

    const { product, calculatedPrice, amount } = useProductPurchaseContext()

    if (undefined === product || undefined === calculatedPrice) {
        return children
    }

    const t = (str: string) => str

    const { taxId, translated: { customFields: { product_price_upon_request, product_rental_offer_request } } } = product

    return ((true !== product_price_upon_request && true !== product_rental_offer_request) ? (
        <>
            <span className={`d-inline-block  fs-3  fw-bold  ${className ?? 'mt-3  mt-md-3'}`}>
                <FormattedPrice unitPrice={amount * calculatedPrice.unitPrice} purchaseUnit={1} referenceUnit={1} taxId={taxId} />
                {calculatedPrice.listPrice && (
                    <span className={'d-block  fs-5  fw-bold  text-danger  text-decoration-line-through'}>
                        <FormattedPrice unitPrice={amount * calculatedPrice.listPrice.price} purchaseUnit={1} referenceUnit={1} taxId={taxId} />
                    </span>
                )}
            </span>
            <Tax taxId={taxId} className={'w-100  d-block  fs-9  fw-semibold  text-gray-500'} longText={true} />
        </>
    ) : (
        <span className={'fs-2  fw-bold  mt-5'}>{t(product_rental_offer_request ? 'rental_offer_request' : 'price_upon_request')}</span>
    ))
}

export default Price
