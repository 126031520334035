'use client'

import { useEffect, useState } from "react"
import googleSendEvent from "@helpers/google-send-event";

const PageView = ({ eventName, ...ntmData }: { [key: string]: any }) => {
    const [loaded, setLoaded] = useState<boolean>()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const syncConsent = (event?: any) => {
            let w: any = window
            let consent: any = null

            const { CookieFirst }: any = w

            if (event) {
                consent = event ? event.detail || {} : {};
            } else if (CookieFirst && CookieFirst.consent) {
                consent = CookieFirst.consent;
            }

            if (consent && false === loaded) {
                setLoaded(true);

                const { necessary: _, advertising } = consent

                if (true === advertising) {
                    googleSendEvent(eventName, ntmData)
                }
            }
        }

        if (true !== loaded) {
            setLoaded(false)
            syncConsent()
        }

        window.addEventListener("cf_consent", syncConsent);
        window.addEventListener("cf_consent_loaded", syncConsent);

        return () => {
            window.removeEventListener("cf_consent", syncConsent);
            window.removeEventListener("cf_consent_loaded", syncConsent);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    return null
}

export default PageView
